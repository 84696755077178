import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: 100
        }}>
          <img src={logo} className="App-logo" alt="logo" />
          <p className='app-name'>
            比比装
          </p>
        </div>


        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
      <div className='footer'>
        <a className='icp' href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302007285'>京公网安备11011302007285号</a>
        <a className='icp' href='https://beian.miit.gov.cn'>京ICP备2024078127号-1</a>
      </div>
    </div>
  );
}

export default App;
